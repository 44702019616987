@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .clip-your-needful-style {
    clip-path: polygon(0 0%, 100% 0, 100% 88%, 0% 100%);
  }
}

/* 2/2 base rules are converted successfully. */
/* Gather results from the console with `copy(window.cssToTailwindResults)` */

/* ℹ️ Base selector: .bubble */
/* ✨ TailwindCSS: "h-16 text-xl p-0 relative text-center text-white w-56" */
/* ⚠️ Some properties could not be matched with Tailwind classes. Use @apply to extend a CSS rule: */
.bubble {
  @apply text-white;
  position: relative;
  background: #7867a7;
  border-radius: 0.4em;
}

/* ℹ️ Base selector: .bubble:after */
/* ✨ TailwindCSS: "border-solid border-r-0 block -mt-4 absolute w-0" */
/* ⚠️ Some properties could not be matched with Tailwind classes. Use @apply to extend a CSS rule: */
.bubble:after {
  @apply text-white;
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 36px solid transparent;
  border-left-color: #00aabb;
  border-right: 0;
  margin-top: -36px;
  margin-right: -36px;
}

.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#myTextarea::placeholder {
  font-style: italic;
}

/* .package-image:hover {
  content: url('./images/shapeHover.png);
}
 */

/* Set initial image */
.parent-div .image {
  content: url('https://res.cloudinary.com/dedriqudy/image/upload/v1681080178/shape_dktlty.png');
}

/* Change image on hover */
.parent-div:hover .image {
  content: url('https://res.cloudinary.com/dedriqudy/image/upload/v1681080178/shape_hover_ovhzfw.png');
}

.parent-div:hover .price {
  color: #fcd129;
  transition: color 150ms ease-in-out;
}
